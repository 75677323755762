import React, { useState } from "react";
import { NavLink } from "react-router-dom";
const HeaderComponent: React.FC = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("/"); // Default active link

  const handleLinkClick = (link: string) => {
    setActiveLink(link);
    closeNav();
    window.scrollTo(0, 0);
  };

  const getLinkClass = (path: string) => {
    return `nav-link scrollto`;
  };

  const openNav = () => {
    setNavOpen(true);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  const overlayStyle = {
    height: navOpen ? "100%" : "0%",
  };

  return (
    <header id="header" className="header fixed-top">
      <style>
        {`
          @media (min-width: 992px) {
            .navbar-toggler {
              display: none;
            }
          }
        `}
      </style>
      <div className="container d-flex align-items-center head-br-btm">
        <NavLink to="/" className="logo me-auto" onClick={() => handleLinkClick("/")}>
          <img src="/assets/img/logo.png" alt="" className="img-fluid" />
        </NavLink>

        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li>
              <NavLink className={getLinkClass("/")} to="/" onClick={() => handleLinkClick("/")}>
                <span className="link-text">Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink className={getLinkClass("/about")} to="/about" onClick={() => handleLinkClick("/about")}>
                <span className="NavLink-text">About Us</span>
              </NavLink>
            </li>
            <li>
              <NavLink className={getLinkClass("/services")} to="/services" onClick={() => handleLinkClick("/services")}>
                <span className="NavLink-text">Services</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink className={getLinkClass("/")} to="#departments" onClick={() => handleLinkClick("/")}>
                <span className="NavLink-text">Projects</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink className={getLinkClass("/news")} to="/news" onClick={() => handleLinkClick("/news")}>
                <span className="NavLink-text">News</span>
              </NavLink>
            </li>
            <li>
              <NavLink className={getLinkClass("/contact-us")} to="/contact-us" onClick={() => handleLinkClick("/contact-us")}>
                <span className="NavLink-text">Contact</span>
              </NavLink>
            </li>
          </ul>
          <div className="navbar-toggler" onClick={openNav} data-bs-toggle="collapse" data-bs-target="#main_nav">
            <span className="navbar-toggler-icon"></span>
          </div>
        </nav>
      </div>

      {/* <!------ OVERLAY MENU START ------> */}
      <div id="myNav" className="overlayp" style={overlayStyle}>
        <div className="menu_lft">
          <div className="log_loc">
            <div className="men_logo">
              {/* <a href="/">
                <img src="/assets/img/logo.png" alt="" />
              </a> */}
              <NavLink to="/" onClick={() => handleLinkClick("/")}>
                <img src="/assets/img/logo.png" alt="" />
              </NavLink>
            </div>
          </div>
        </div>

        <div className="menu_rgt">
          {/* <!-- Button to close the overlay navigation --> */}
          <a href="#" className="closebtn" onClick={closeNav}>
            &times;
          </a>

          {/* <!-- Overlay content --> */}
          <div className="overlayp-content">
            <ul>
              <li>
                <NavLink to="/" onClick={() => handleLinkClick("/")}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" onClick={() => handleLinkClick("/")}>
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink to="/services" onClick={() => handleLinkClick("/")}>
                  Services
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/">Projects</NavLink>
              </li> */}
              <li>
                <NavLink to="/news" onClick={() => handleLinkClick("/")}>
                  News
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact-us" onClick={() => handleLinkClick("/")}>
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="bottom-bx">
            <div className="mail_call">
              <a className="mail" href="mailto:contact@dronalogitech.com">
                contact@dronalogitech.com
              </a>
              <a style={{ borderRight: "0" }} className="call" href="tel:+91 95993 11400">
                +91 95993 11400
              </a>
            </div>

            <div className="social">
              <h3>Follow us</h3>
              <ul>
                {/* <li>
                  <a target="_blank" href="/" className="you"></a>
                </li> */}
                <li>
                  <a target="_blank" href="https://www.linkedin.com/company/drona-logitech-pvt-ltd" className="you2"></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/dronalogitech/" className="you1"></a>
                </li>
                <li>
                  <a target="_blank" href="https://www.facebook.com/profile.php?id=61553032583927&mibextid=LQQJ4d" className="you"></a>
                </li>
                {/* <li>
                  <a target="_blank" href="/" className="you3"></a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!------ OVERLAY MENU END ------> */}
    </header>
  );
};

export default HeaderComponent;
