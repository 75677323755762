import { NavigateFunction } from "react-router-dom";

export function manageMainElementVisibility(pathname: string) {
  const mainElement = document.getElementById("hid");
  const contactElement = document.getElementById("contact");
  if (mainElement) {
    if (pathname === "/") {
      mainElement.classList.remove("hid");
    } else {
      mainElement.classList.add("hid");
    }
  }
  if (contactElement) {
    if (pathname === "/contact-us") {
      contactElement.classList.add("hid");
    } else {
      contactElement.classList.remove("hid");
    }
  }
}

export const setupLinkInterception = (navigate: NavigateFunction): (() => void) => {
  const handleClick = (event: Event): void => {
    event.preventDefault();
    const target = event.currentTarget as HTMLAnchorElement;
    navigate(target.getAttribute("href") ?? "");

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  const links = document.querySelectorAll(".react-link");
  links.forEach((link) => link.addEventListener("click", handleClick));

  return (): void => {
    links.forEach((link) => link.removeEventListener("click", handleClick));
  };
};

export const formatDate = (date: Date) => {
  return new Date(date).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric" });
};
