import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { manageMainElementVisibility } from "../utils";
import { API_URL } from "./News";

const Services: React.FC = () => {
  const location = useLocation();
  const [featuresData, setFeaturesData] = useState([]);

  useEffect(() => {
    manageMainElementVisibility(location.pathname);

    const fetchData = async () => {
      try {
        const response = await fetch(API_URL + "/api/services?sort=sequence");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setFeaturesData(data.docs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [location.pathname]);
  return (
    <>
      <div className="inner_banner d-flex align-items-center" style={{ backgroundImage: 'url("/assets/img/home-banner1.jpg")' }}>
        <div className="container position-relative d-flex flex-column align-items-center">
          <h1>Services</h1>
          <ol>
            <li>
              <a href="/" className="react-link">
                Home
              </a>
            </li>
            <li>Services</li>
          </ol>
        </div>
      </div>

      <section id="features" className="features">
        <div className="container">
          {/* Features Item */}
          {featuresData.map((item: any, index) => (
            <div className="features-item" data-aos="fade-up" key={item.id}>
              <h2>{item.title}</h2>
              <div className="row gy-4 align-items-center">
                <div className={`col-md-5 ${index % 2 === 0 ? "order-md-1" : "order-md-2"}`}>
                  <div className="block-img">
                    <div className="block-img-inner">
                      <div className="block-img-element">
                        <img src={API_URL + item.url} className="img-fluid" alt={item.title} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-md-7 ${index % 2 === 0 ? "order-md-2 ps-md-5" : "order-md-1 pe-md-5"}`}>
                  <div className="discription" dangerouslySetInnerHTML={{ __html: item.content_html }} />
                </div>
              </div>
            </div>
          ))}
          {/* Features Item */}
        </div>
      </section>
    </>
  );
};

export default Services;
