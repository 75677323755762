import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { manageMainElementVisibility } from "../utils";

const AboutUs: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    manageMainElementVisibility(location.pathname);
  }, [location.pathname]);
  return (
    <>
      <div className="inner_banner d-flex align-items-center" style={{ backgroundImage: 'url("/assets/img/homepage-banner2.jpg")' }}>
        <div className="container position-relative d-flex flex-column align-items-center">
          <h1>About Us</h1>
          <ol>
            <li>
              <a href="/" className="react-link">
                Home
              </a>
            </li>
            <li>About Us</li>
          </ol>
        </div>
      </div>

      <section className="inner-aboutus bg-pattern bg-gradients show-lines">
        <div className=" container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="block-caption">
                <h2 className="heading-2 gradient-animation-dark mb-4">About Us</h2>
                <div className="text-paragraph mb-4">
                  <p>
                    At Drona Logitech, we are at the forefront of the 3PL/4PL Industry, harnessing innovation and technology to revolutionize Physical
                    Supply chain through our services. Our team is committed to integrating the latest technological advancements into our operations,
                    ensuring that we can offer cutting-edge solutions that are ahead of the curve. Through the use of advanced digital platforms,
                    real-time tracking systems, and predictive maintenance software, we are able to provide efficient and reliable logistics services
                    that are unmatched in the industry.
                  </p>
                  <p>
                    By leveraging innovation and technology, we are not just in the business of running stereotype SCM Business; we are in the
                    business of shaping the future of logistics. Our forward-thinking approach allows us to stay ahead of the digital era, offering
                    our clients strategic advantages that go beyond traditional logistics. At Drona Logitech, you can trust that we are constantly
                    pushing the boundaries of what is possible, ensuring that your business stays at the forefront of innovation and technological
                    advancements.
                  </p>
                </div>
                <div className="block-buttons" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="block-img">
                <div className="block-img-inner">
                  <div className="block-img-element">
                    <img src="/assets/img/homepage-banner1.jpg" alt="About Us" title="About Us" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-iso" />
        <div className="gradient-elements" />
      </section>
      <section id="what_sets" className="what_sets">
        <div className="container" data-aos="fade-up">
          <h2>What Sets Us Apart?</h2>
          <div className="row">
            <div className="image col-lg-6" style={{ backgroundImage: 'url("/assets/img/state-img.jpg")' }} data-aos="fade-right" />
            <div className="col-lg-6" data-aos="fade-left" data-aos-delay={100}>
              <div className="icon-box mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay={150}>
                <i className="ico">
                  <img src="/assets/img/sets-us-part-icon1.png" alt="" title="" />
                </i>
                <h4>State-of-the-art Facilities</h4>
                <p>
                  We provide cutting-edge inventory management and tracking solutions to streamline supply chain operations with automated warehouse
                  systems, digital platforms, RFID, predictive maintenance, autonomous vehicles, blockchain, and IoT technology.
                </p>
                {/* <p>Utilize our cutting-edge technological solutions to streamline your supply chain, including real-time tracking through our proprietary i-SMS app, precise inventory management, and data-driven analysis.</p> */}
                {/* <p>We offer advanced inventory management and real-time tracking solutions to optimize your supply chain. Our facilities include automated warehouse systems, digital supply chain platforms, RFID technology, predictive maintenance, autonomous vehicles, blockchain, and IoT devices for enhanced control.</p> */}
              </div>
              <div className="icon-box mt-4" data-aos="zoom-in" data-aos-delay={150}>
                <i className="ico">
                  <img src="/assets/img/sets-us-part-icon2.png" alt="" title="" />
                </i>
                <h4>Tailored Solutions</h4>
                <p>
                  Get personalized logistics plans created to meet the specific needs of your business, maximizing efficiency and cost-effectiveness.
                </p>
              </div>
              <div className="icon-box mt-4" data-aos="zoom-in" data-aos-delay={150}>
                <i className="ico">
                  <img src="/assets/img/sets-us-part-icon3.png" alt="" title="" />
                </i>
                <h4>Extensive Experience in the Industry</h4>
                <p>
                  Take advantage of our extensive experience in the logistics industry, guaranteeing efficient and knowledgeable management of your
                  operations.
                </p>
              </div>
              <div className="icon-box mt-4" data-aos="zoom-in" data-aos-delay={150}>
                <i className="ico">
                  <img src="/assets/img/sets-us-part-icon4.png" alt="" title="" />
                </i>
                <h4>Dependable and Effective Service</h4>
                <p>Rely on our proven record of dependable and efficient logistics services, ensuring prompt and precise delivery of your goods.</p>
              </div>
              <div className="icon-box mt-4" data-aos="zoom-in" data-aos-delay={150}>
                <i className="ico">
                  <img src="/assets/img/sets-us-part-icon5.png" alt="" title="" />
                </i>
                <h4>Low-Cost Module</h4>
                <p>
                  Our low-cost module in distribution, warehousing, packaging, supply chain, and in-plant solutions provides affordable options for
                  streamlining operations, optimizing storage and distribution, improving packaging processes, and enhancing overall supply chain
                  efficiency.
                </p>
              </div>
              <div className="icon-box mt-4" data-aos="zoom-in" data-aos-delay={150}>
                <i className="ico">
                  <img src="/assets/img/sets-us-part-icon6.png" alt="" title="" />
                </i>
                <h4>Leading-Edge Technological Solutions</h4>
                <p>
                  Utilize our forefront technological solutions to streamline your supply chain, including real-time tracking through our proprietary
                  i-SMS app, precise inventory management, and data-driven analysis.
                </p>
              </div>
              <div className="icon-box mt-4" data-aos="zoom-in" data-aos-delay={150}>
                <i className="ico">
                  <img src="/assets/img/sets-us-part-icon7.png" alt="" title="" />
                </i>
                <h4>Robust Digital Platform</h4>
                <p>
                  Our robust digital platform offers a comprehensive solution for warehousing, in-plant operations, transportation, distribution,
                  packaging, project management, and supply chain consultancy. It leverages advanced technology to streamline operations, reduce
                  costs, and improve overall supply chain efficiency. With real-time tracking and analytics, our platform empowers businesses to make
                  informed decisions and optimize their logistics strategies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-content ">
        <div className="container">
          <div className="block-heading mb-5">
            <h2 className="heading-default heading mb-30">Our Mission &amp; Vision</h2>
          </div>
          <ul className="card-list">
            <li>
              <div className="card-item">
                <h3 className="card-title heading-1 gradient-animation-dark">Mission</h3>
                <div className="card-description">
                  <p>
                    Leading the industry by providing customised, tailor-made logistic solutions that empower customers to optimise their costs while
                    leveraging tech excellence, reinforcing a dedicated, digitised and sustainable experience.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="card-item">
                <h3 className="card-title heading-1 gradient-animation-dark">Vision</h3>
                <div className="card-description">
                  <p>
                    By 2027, our goal is to establish ourselves as the unequivocal and most trusted strategic logistic partner for our customers,
                    entrusted with the seamless management and execution of comprehensive 4PL operations.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
