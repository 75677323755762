import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { manageMainElementVisibility } from "../utils";

const PageNotFound: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    manageMainElementVisibility(location.pathname);
  }, [location.pathname]);
  return (
    <>
      <div className="inner_banner d-flex align-items-center" style={{ backgroundImage: 'url("/assets/img/contact-banner.jpg")' }}>
        <div className="container position-relative d-flex flex-column align-items-center">
          <h1>Page Not Found!</h1>
          <ol>
            <li>
              <a href="/" className="react-link">
                Home
              </a>
            </li>
            <li>Page Not Found</li>
          </ol>
        </div>
      </div>
      <section className="PageNotFound">
        <div className="container">
          <div className="section-header">
            <h2 className="text-center">
              404
              <br />
              Page Not Found!
            </h2>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
