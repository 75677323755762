import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatDate, manageMainElementVisibility } from "../utils";

export interface Blog {
  id: string;
  title: string;
  publishedAt: string;
  imageUrl?: string;
  author: string;
  content: any;
  relatedPosts: any;
  tags: any;
  description: string;
  keywords: string;
  t: string;
}

export const API_URL = "https://dronalogitech.com:8080";

const News: React.FC = () => {
  const location = useLocation();

  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [category, setCategory] = useState<string>("");

  useEffect(() => {
    manageMainElementVisibility(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        let url = `${API_URL}/api/posts?page=${currentPage}`;
        const queryParams = new URLSearchParams(location.search);
        const category = queryParams.get("category");
        if (category) {
          url += `&where[categories.title][equals]=${category}`;
          setCategory(category);
        } else {
          setCategory("");
        }
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch blogs");
        }
        const data = await response.json();
        const blogDocs = data.docs || []; // Extract the docs array from the response
        const extractedBlogs = blogDocs.map((doc: any) => ({
          id: doc.id,
          title: doc.title,
          publishedAt: formatDate(doc.publishedAt),
          imageUrl: API_URL + doc.layout[0]?.media?.url || "",
          author: doc.populatedAuthors[0]?.name,
          t: doc.t,
        }));
        setBlogs(extractedBlogs);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBlogs();
  }, [location.search, currentPage]);

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const getPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <>
      <div className="inner_banner d-flex align-items-center" style={{ backgroundImage: 'url("/assets/img/contact-banner.jpg")' }}>
        <div className="container position-relative d-flex flex-column align-items-center">
          <h1>News &amp; Media</h1>
          <ol>
            <li>
              <a href="/" className="react-link">
                Home
              </a>
            </li>
            <li>News &amp; Media</li>
          </ol>
        </div>
      </div>
      <section id="blog" className="news blog">
        <div className="container">
          <div className="section-header mb-5">
            <h2>{category ? category : "News & Media"}</h2>
          </div>
          <div className="row gy-4 posts-list">
            {blogs && blogs.length > 0
              ? blogs.map((blog) => (
                  <div key={blog.id} className="col-xl-4 col-lg-6">
                    <article>
                      <div className="post-img">
                        <img src={blog.imageUrl} alt={blog.title} className="img-fluid" />
                      </div>
                      <div className="post-meta d-flex align-items-center">
                        <p className="post-date">
                          <time dateTime={blog.publishedAt}>{blog.publishedAt}</time>
                        </p>
                        <p className="post-author">| By : {blog.author}</p>
                      </div>
                      <h2 className="title">
                        <a href={`/news-detail/${blog.t}`}>{blog.title}</a>
                      </h2>
                      <p className="more">
                        <a href={`/news-detail/${blog.t}`}>READ MORE</a>
                      </p>
                    </article>
                  </div>
                ))
              : category && (
                  // Render a message or placeholder
                  <div className="">{/* <p>No posts available for this category.</p> */}</div>
                )}
          </div>
          <div className="pagination d-flex justify-content-center">
            <ul>
              {getPageNumbers().map((page) => (
                <li key={page} className={currentPage === page ? "active" : ""}>
                  <a href="#" onClick={() => handlePageClick(page)}>
                    {page}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default News;
