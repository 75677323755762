import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { manageMainElementVisibility } from "../utils";

const ContactUs: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    manageMainElementVisibility(location.pathname);
  }, [location.pathname]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await fetch("https://dronalogitech.com:8080/api/contacts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Handle success
        alert("Your message has been submitted successfully!");
        // Clear the form fields
        window.location.reload();
      } else {
        // Handle errors
      }
    } catch (error) {
      // Handle errors
      alert("Submission failed! Please try again later...");
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="inner_banner d-flex align-items-center" style={{ backgroundImage: 'url("/assets/img/contact-banner.jpg")' }}>
        <div className="container position-relative d-flex flex-column align-items-center">
          <h1>Contact Us</h1>
          <ol>
            <li>
              <a href="/" className="react-link">
                Home
              </a>
            </li>
            <li>Contact Us</li>
          </ol>
        </div>
      </div>

      <section className="inner_contacts">
        <div className="container">
          <div className="section-header">
            <h2>Let's Talk</h2>
            <h3>Got a project on your mind? Let's discuss about the details.</h3>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-md-7 mb-5">
                  <form id="contactForm" onSubmit={handleSubmit} method="post" className="php-email-form" autoComplete="off">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control form-control-lg form-control-a"
                            placeholder="Your Name"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-3">
                        <div className="form-group">
                          <input
                            name="email"
                            type="email"
                            className="form-control form-control-lg form-control-a"
                            placeholder="Your Email"
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="form-group">
                          <input
                            type="text"
                            name="mobile"
                            maxLength={10}
                            className="form-control form-control-lg form-control-a"
                            placeholder="Phone No."
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            className="form-control"
                            cols={45}
                            rows={4}
                            placeholder="Message"
                            required={true}
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 text-center mt-4">
                        <button type="submit" className="btn btn-a">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-5">
                  <div className="icon-box section-b2">
                    <div className="icon-box-icon">
                      <span className="bi bi-geo-alt" />
                    </div>
                    <div className="icon-box-content table-cell">
                      <div className="icon-box-title">
                        <h4 className="icon-title">Corporate Office</h4>
                      </div>
                      <div className="icon-box-content">
                        <p className="mb-1">
                          4th Floor, Enkay Centre,
                          <br />
                          Plot No. A, Vanjiya Nikunj, Udyog Vihar,
                          <br />
                          Phase V, Sector 19, Gurugram, Haryana - 122016
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="icon-box section-b2">
                    <div className="icon-box-icon">
                      <span className="bi bi-envelope" />
                    </div>
                    <div className="icon-box-content table-cell">
                      <div className="icon-box-title">
                        <h4 className="icon-title">Say Hello</h4>
                      </div>
                      <div className="icon-box-content">
                        <p className="mb-1">
                          Email.
                          <span className="color-a"> contact@dronalogitech.com</span>
                        </p>
                        <p className="mb-1">
                          Phone.
                          <span className="color-a"> +91 95993 11400</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="icon-box">
                    <div className="icon-box-icon">
                      <span className="bi bi-share" />
                    </div>
                    <div className="icon-box-content table-cell">
                      <div className="icon-box-title">
                        <h4 className="icon-title">Social networks</h4>
                      </div>
                      <div className="icon-box-content">
                        <div className="socials-footer">
                          <ul className="list-inline">
                            {/* <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="bi bi-facebook" aria-hidden="true" />
                              </a>
                            </li> */}
                            {/* <li className="list-inline-item">
                              <a href="#" className="link-one">
                                <i className="bi bi-twitter" aria-hidden="true" />
                              </a>
                            </li> */}
                            <li className="list-inline-item">
                              <a href="https://www.linkedin.com/company/drona-logitech-pvt-ltd" target="_blank" className="link-one">
                                <i className="bi bi-linkedin" aria-hidden="true" />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="https://www.instagram.com/dronalogitech/" target="_blank" className="link-one">
                                <i className="bi bi-instagram" aria-hidden="true" />
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="https://www.facebook.com/profile.php?id=61553032583927&mibextid=LQQJ4d" target="_blank" className="link-one">
                                <i className="bi bi-facebook" aria-hidden="true" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 mt-5">
              <div className="contact-map box">
                <div id="map" className="contact-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.411587887184!2d77.08153338101296!3d28.497262013183217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19d1a61b6607%3A0x981a8e7c1ed6e187!2sDrona%20Logitech%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1702795495350!5m2!1sen!2sin"
                    width="100%"
                    height={350}
                    style={{ border: 0 }}
                    allowFullScreen={true}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
