import React, { useEffect, useState } from "react";
import { manageMainElementVisibility } from "../utils";
import { useLocation } from "react-router-dom";
import { API_URL } from "../pages/News";

declare var Swiper: any;

interface Slide {
  title: string;
  subtitle: string;
  imageUrl: string;
}

const SliderComponent: React.FC = () => {
  const location = useLocation();

  const [slides, setSlides] = useState<Slide[]>([]);

  useEffect(() => {
    manageMainElementVisibility(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await fetch(API_URL + "/api/banners");
        if (!response.ok) {
          throw new Error("Failed to fetch slides");
        }
        const data = await response.json();
        const slidesDocs = data.docs || []; // Extract the docs array from the response
        const extractedSlides = slidesDocs.map((doc: any) => ({
          id: doc.id,
          title: doc.title,
          subtitle: doc.subtitle,
          imageUrl: API_URL + doc.url || "",
        }));
        setSlides(extractedSlides);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSlides();
  }, []);

  useEffect(() => {
    // Call the setSwiperScript function after the component has rendered
    setSwiperScript();
  }, [slides]);

  return (
    // <!-- start of hero -->
    <section className="hero-slider hero-style">
      {/* <!-- Slider main container --> */}
      <div className="swiper-container">
        {/* <!-- Additional required wrapper --> */}
        <div className="swiper-wrapper">
          {/* <!-- Slides --> */}
          {/* Dynamic */}
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`swiper-slide slide-${index + 1}`}
              style={{ background: `url('${slide.imageUrl}')`, backgroundSize: "cover" }}
            >
              <div className="container">
                <div className="slider-container">
                  <div className="animated-area">
                    <h1 className="slider-title">{slide.title}</h1>
                  </div>
                  <h4 className="slider-sub-title">{slide.subtitle}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <!-- If we need navigation buttons --> */}
        <div className="swiper-pagination"></div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
    </section>
    // <!-- end of hero slider -->
  );
};

export default SliderComponent;

function setSwiperScript() {
  // Code to execute after SliderComponent is fully rendered
  var mySwiper = new Swiper(".swiper-container", {
    loop: true,
    spaceBetween: 50,
    speed: 1000,
    effect: "fade",
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      lockClass: "swiper-pagination-loc",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
  });
}
