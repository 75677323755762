import React, { useEffect } from "react";

const CommonScript: React.FC = () => {
  useEffect(() => {
    const scripts = ["bootstrap.bundle.min.js", "main.js", "purecounter.js", "validate.js", "general.js"];
    const urlScripts = [
      "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js",
      "https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.6/swiper-bundle.min.js",
    ];
    // for (const urlScript of urlScripts) {
    //   const scriptElement = document.createElement("script");
    //   scriptElement.src = urlScript;
    //   scriptElement.async = true;
    //   document.body.appendChild(scriptElement);
    // }
    for (const script of scripts) {
      const scriptElement = document.createElement("script");
      scriptElement.src = `/assets/js/${script}`;
      scriptElement.async = true;
      document.body.appendChild(scriptElement);
    }
  }, []);

  return null;
};

export default CommonScript;
