import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setupLinkInterception } from "../utils";

const NavigationHandler: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const cleanup = setupLinkInterception(navigate);
    return cleanup;
  }, [navigate]);

  return null; // This component doesn't need to render anything
};

export default NavigationHandler;
