import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { formatDate, manageMainElementVisibility } from "../utils";
import { API_URL, Blog } from "./News";
import { Helmet } from "react-helmet-async";

const NewsDetail: React.FC = () => {
  const location = useLocation();

  const { slug } = useParams<{ slug: string }>();

  const [blog, setBlog] = useState<Blog | null>(null);

  const [categoryCount, setCategoryCount] = useState<{ [key: string]: number }>({});
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    manageMainElementVisibility(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`${API_URL}/api/posts?where[t][equals]=${slug}`);
        if (!response.ok) {
          throw new Error("Failed to fetch blog");
        }
        const data = await response.json();
        const postData = data.docs[0]; // API returns an array of posts
        setBlog({
          id: postData.id,
          title: postData.title,
          publishedAt: formatDate(postData.publishedAt),
          imageUrl: `${API_URL}${postData.layout[0]?.media?.url || ""}`,
          author: postData.populatedAuthors[0]?.name,
          content: postData.layout,
          relatedPosts: postData.relatedPosts,
          tags: postData.tags,
          description: postData.description,
          keywords: postData.keywords,
          t: postData.t,
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchBlog();
  }, [slug]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(API_URL + "/api/categories");
        if (!response.ok) {
          throw new Error("Failed to fetch categories!");
        }
        const data = await response.json();
        const categoryData = data.docs;
        // Count occurrences of each category
        const countMap: { [key: string]: number } = {};
        categoryData.forEach((item: any) => {
          const category = item.title;
          countMap[category] = (countMap[category] || 0) + 1;
        });
        setCategoryCount(countMap);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCategories();
  }, []);

  // useEffect(() => {
  //   const fetchTags = async () => {
  //     try {
  //       const response = await fetch(API_URL + "/api/tags");
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch tags!");
  //       }
  //       const data = await response.json();
  //       const tagsData = data.docs;
  //       const tags: string[] = [];
  //       tagsData.forEach((item: any) => {
  //         tags.push(item.title);
  //       });
  //       setTags(tags);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchTags();
  // }, []);

  if (!blog) {
    return <div></div>;
  }

  let isFirstMediaBlock = true;

  return (
    <>
      <Helmet>
        <title>{blog?.title || "Loading..."}</title>
        <meta name="description" content={blog?.description || "Loading..."} />
        <meta name="keywords" content={blog?.keywords || "Loading..."} />
      </Helmet>
      <div className="inner_banner d-flex align-items-center" style={{ backgroundImage: 'url("/assets/img/contact-banner.jpg")' }}>
        <div className="container position-relative d-flex flex-column align-items-center">
          <h1>News &amp; Media</h1>
          <ol>
            <li>
              <a href="/" className="react-link">
                Home
              </a>
            </li>
            <li>News &amp; Media</li>
          </ol>
        </div>
      </div>
      <section id="blog-details" className="blog-details">
        <div className="container" data-aos="fade-up" data-aos-delay={100}>
          <div className="row g-5">
            <div className="col-lg-8">
              <article className="article">
                <div className="post-img">
                  <img src={blog.imageUrl} alt="" className="img-fluid" />
                </div>
                <h2 className="title">{blog.title}</h2>
                <div className="meta-top">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person" /> <a href="#">{blog.author}</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock" />{" "}
                      <a href="#">
                        <time dateTime={blog.publishedAt}>{blog.publishedAt}</time>
                      </a>
                    </li>
                    {/* <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots" /> <a href="#">12 Comments</a>
                    </li> */}
                  </ul>
                </div>
                {/* End meta top */}
                <div className="content">
                  {blog.content.map((block: any) => {
                    if (block.blockType === "mediaBlock" && isFirstMediaBlock) {
                      isFirstMediaBlock = false; // Set the flag to false after encountering the first media block
                      return null; // Skip rendering the first media block
                    }

                    if (block.blockType === "mediaBlock") {
                      return (
                        <div className="post-imgs" key={block.id}>
                          <img src={API_URL + block.media.url} alt={block.media.alt} width={block.media.width} className="img-fluid" />
                        </div>
                      );
                    }
                    if (block.blockType === "content") {
                      // Iterate over columns in the content block
                      return block.columns.map((column: any) => (
                        <div key={column.id}>
                          {/* Render the HTML content */}
                          <div dangerouslySetInnerHTML={{ __html: column.content_html }} />
                        </div>
                      ));
                    }
                    return null;
                  })}
                </div>
                {/* End post content */}
                <div className="meta-bottom">
                  {/* <i className="bi bi-folder" /> */}
                  {/* <ul className="cats"> */}
                  {/* <li>
                      <a href="#">Business</a>
                    </li> */}
                  {/* </ul> */}
                  <i className="bi bi-tags" />
                  <ul className="tags">
                    {/* <li>
                      <a href="#">Creative</a>
                    </li>
                    <li>
                      <a href="#">Tips</a>
                    </li> */}
                    {blog.tags &&
                      blog.tags.map((tag: any) => (
                        <li>
                          <a href="#" key={tag.index}>
                            {tag.title}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
                {/* End meta bottom */}
              </article>
              {/* End post article */}
              {/* <div className="blog-author d-flex align-items-center">
                <img src="/assets/img/blog-author.jpg" className="rounded-circle flex-shrink-0" alt="" />
                <div>
                  <h4>Abhijit Patel</h4>
                  <div className="social-links">
                    <a href="">
                      <i className="bi bi-twitter" />
                    </a>
                    <a href="">
                      <i className="bi bi-facebook" />
                    </a>
                    <a href="">
                      <i className="biu bi-instagram" />
                    </a>
                  </div>
                  <p>
                    Itaque quidem optio quia voluptatibus dolorem dolor. Modi eum sed possimus accusantium. Quas repellat voluptatem officia numquam
                    sint aspernatur voluptas. Esse et accusantium ut unde voluptas.
                  </p>
                </div>
              </div> */}
              {/* End post author */}
              <div className="comments">
                {/* <h4 className="comments-count">8 Comments</h4> */}
                {/* <div id="comment-1" className="comment">
                  <div className="d-flex">
                    <div className="comment-img">
                      <img src="/assets/img/blog-3.jpg" alt="" />
                    </div>
                    <div>
                      <h5>
                        <a href="">Georgia Reader</a>{" "}
                        <a href="#" className="reply">
                          <i className="bi bi-reply-fill" /> Reply
                        </a>
                      </h5>
                      <time dateTime="2023-01-01">01 Jan, 2024</time>
                      <p>
                        Et rerum totam nisi. Molestiae vel quam dolorum vel voluptatem et et. Est ad aut sapiente quis molestiae est qui cum soluta.
                        Vero aut rerum vel. Rerum quos laboriosam placeat ex qui. Sint qui facilis et.
                      </p>
                    </div>
                  </div>
                </div> */}
                {/* End comment #1 */}
                <div id="comment-2" className="comment">
                  {/* <div className="d-flex">
                    <div className="comment-img">
                      <img src="/assets/img/blog-3.jpg" alt="" />
                    </div>
                    <div>
                      <h5>
                        <a href="">Aron Alvarado</a>{" "}
                        <a href="#" className="reply">
                          <i className="bi bi-reply-fill" /> Reply
                        </a>
                      </h5>
                      <time dateTime="2023-01-01">01 Jan, 2024</time>
                      <p>Ipsam tempora sequi voluptatem quis sapiente non. Autem itaque eveniet saepe. Officiis illo ut beatae.</p>
                    </div>
                  </div> */}
                  <div id="comment-reply-1" className="comment comment-reply">
                    {/* <div className="d-flex">
                      <div className="comment-img">
                        <img src="/assets/img/blog-3.jpg" alt="" />
                      </div>
                      <div>
                        <h5>
                          <a href="">Lynda Small</a>
                          <a href="#" className="reply">
                            <i className="bi bi-reply-fill" /> Reply
                          </a>
                        </h5>
                        <time dateTime="2023-01-01">01 Jan, 2024</time>
                        <p>
                          Enim ipsa eum fugiat fuga repellat. Commodi quo quo dicta. Est ullam aspernatur ut vitae quia mollitia id non. Qui ad quas
                          nostrum rerum sed necessitatibus aut est. Eum officiis sed repellat maxime vero nisi natus. Amet nesciunt nesciunt qui illum
                          omnis est et dolor recusandae Recusandae sit ad aut impedit et. Ipsa labore dolor impedit et natus in porro aut. Magnam qui
                          cum. Illo similique occaecati nihil modi eligendi. Pariatur distinctio labore omnis incidunt et illum. Expedita et
                          dignissimos distinctio laborum minima fugiat. Libero corporis qui. Nam illo odio beatae enim ducimus. Harum reiciendis error
                          dolorum non autem quisquam vero rerum neque.
                        </p>
                      </div>
                    </div> */}
                    {/* <div id="comment-reply-2" className="comment comment-reply">
                      <div className="d-flex">
                        <div className="comment-img">
                          <img src="/assets/img/blog-3.jpg" alt="" />
                        </div>
                        <div>
                          <h5>
                            <a href="">Sianna Ramsay</a>{" "}
                            <a href="#" className="reply">
                              <i className="bi bi-reply-fill" /> Reply
                            </a>
                          </h5>
                          <time dateTime="2023-01-01">01 Jan, 2024</time>
                          <p>
                            Et dignissimos impedit nulla et quo distinctio ex nemo. Omnis quia dolores cupiditate et. Ut unde qui eligendi sapiente
                            omnis ullam. Placeat porro est commodi est officiis voluptas repellat quisquam possimus. Perferendis id consectetur
                            necessitatibus.
                          </p>
                        </div>
                      </div>
                    </div> */}
                    {/* End comment reply #2*/}
                  </div>
                  {/* End comment reply #1*/}
                </div>
                {/* End comment #2*/}
                {/* <div className="reply-form">
                  <h4>Leave a Reply</h4>
                  <p>Your email address will not be published. Required fields are marked * </p>
                  <form action="">
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <input name="name" type="text" className="form-control" placeholder="Your Name*" />
                      </div>
                      <div className="col-md-6 form-group">
                        <input name="email" type="text" className="form-control" placeholder="Your Email*" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col form-group">
                        <input name="website" type="text" className="form-control" placeholder="Your Website" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col form-group">
                        <textarea name="comment" className="form-control" placeholder="Your Comment*" defaultValue={""} />
                      </div>
                    </div>
                    <div className="text-center">
                      <button type="submit" className="btn btn-primary">
                        Post Comment
                      </button>
                    </div>
                  </form>
                </div> */}
              </div>
              {/* End blog comments */}
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                {/* <div className="sidebar-item search-form">
                  <h3 className="sidebar-title">Search</h3>
                  <form action="" className="mt-3">
                    <input type="text" />
                    <button type="submit">
                      <i className="bi bi-search" />
                    </button>
                  </form>
                </div> */}
                {/* End sidebar search formn*/}
                <div className="sidebar-item categories">
                  <h3 className="sidebar-title">Categories</h3>
                  <ul className="mt-3">
                    {/* <li>
                      <a href="#">
                        General <span>(25)</span>
                      </a>
                    </li> */}
                    {Object.entries(categoryCount).map(([category, count]: [string, number]) => (
                      <li key={category}>
                        <a href={`/news?category=${category}`}>
                          {category} {/* <span>({count})</span> */}
                        </a>
                      </li>
                    ))}
                    {/* <li>
                      <a href="#">
                        Lifestyle <span>(12)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Travel <span>(5)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Design <span>(22)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Creative <span>(8)</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        Educaion <span>(14)</span>
                      </a>
                    </li> */}
                  </ul>
                </div>
                {/* End sidebar categories*/}
                <div className="sidebar-item recent-posts">
                  <h3 className="sidebar-title">Recent Posts</h3>
                  {blog.relatedPosts && blog.relatedPosts.length > 0 ? (
                    // Render recent posts if there are related posts available
                    blog.relatedPosts.map((post: any) => (
                      <div className="post-item" key={post.id}>
                        <img src={API_URL + (post.layout[0]?.media?.url || "")} alt="" className="flex-shrink-0" />
                        <div>
                          <h4>
                            <a href={`/news-detail/${post.t}`}>{post.title}</a>
                          </h4>
                          <time dateTime={post.publishedAt}>{formatDate(post.publishedAt)}</time>
                        </div>
                      </div>
                    ))
                  ) : (
                    // Render a message or placeholder if there are no related posts available
                    <p>No related posts available.</p>
                  )}
                </div>

                {/* End sidebar recent posts*/}
                <div className="sidebar-item tags">
                  <h3 className="sidebar-title">Tags</h3>
                  <ul className="mt-3">
                    {/* <li>
                      <a href="#">App</a>
                    </li> */}
                    {blog.tags &&
                      blog.tags.map((tag: any) => (
                        <li>
                          <a href="#" key={tag.index}>
                            {tag.title}
                          </a>
                        </li>
                      ))}
                    {/* <li>
                      <a href="#">IT</a>
                    </li>
                    <li>
                      <a href="#">Business</a>
                    </li>
                    <li>
                      <a href="#">Mac</a>
                    </li>
                    <li>
                      <a href="#">Design</a>
                    </li>
                    <li>
                      <a href="#">Office</a>
                    </li>
                    <li>
                      <a href="#">Creative</a>
                    </li>
                    <li>
                      <a href="#">Studio</a>
                    </li>
                    <li>
                      <a href="#">Smart</a>
                    </li>
                    <li>
                      <a href="#">Tips</a>
                    </li>
                    <li>
                      <a href="#">Marketing</a>
                    </li> */}
                  </ul>
                </div>
                {/* End sidebar tags*/}
              </div>
              {/* End Sidebar */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsDetail;
